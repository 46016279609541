<template>
  <v-container fluid tag="section">
    <v-layout align-top justify-center>
      <v-card width="400" height="300" class="mx-auto mt-2">
        <v-card-text class="pb-0">
          <v-spacer />
          <h1 class="text-h3 text-center">登入</h1>
          <v-spacer />
        </v-card-text>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="account"
              prepend-icon="mdi-account-circle"
              @keyup.enter="loginAccount"
            />
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="loginAccount"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="info" class="text-h4" @click="loginAccount">登入</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "login",
  data() {
    return {
      showPassword: false,
      account: "",
      password: "",
    };
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapActions(["submitToken"]),
    loginAccount() {
      if (this.account.length > 0 && this.password.length > 0) {
        let url = process.env.VUE_APP_SERVER_URL + "/backend/api-token-auth/";
        console.log(
          " process.env.VUE_APP_SERVER_URL" + process.env.VUE_APP_SERVER_URL
        );
        let currentObj = this;
        this.axios
          .post(url, {
            username: this.account,
            password: this.password,
          })
          .then(function (response) {
            // console.log(response.data);
            let info = JSON.parse(JSON.stringify(response.data));
            if ("access" in info) {
              let res = VueJwtDecode.decode(info["access"]);

              let username = info["username"];
              if ("username" in res) {
                username = res["username"];
              }
              let is_staff = info["is_staff"];
              if ("is_staff" in res) {
                is_staff = res["is_staff"];
              }

              currentObj.submitToken({
                username: username,
                token: info["access"],
                is_staff: is_staff,
              });
              currentObj.toHome(info["is_admin"]);
            } else {
              console.log("Fail to login");
              alert("登入失敗 請檢查帳號密碼");
            }
          })
          .catch(function (error) {
            console.log(error);
            if (error.response) {
              let info = JSON.parse(JSON.stringify(error.response.data));
              if ("non_field_errors" in info) {
                alert("登入失敗 請檢查帳號密碼");
              }
            } else {
              alert("網路狀況 請稍後再試");
            }
          });
      } else {
        alert("請輸入正確資料");
      }
    },
    toHome(is_admin) {
      let url = "";
      if (is_admin) {
        url = "UserMgr";
      } else {
        url = "Index";
      }
      this.$router.push({
        name: url,
        params: {
          // item: item
        },
      });
    },
  },
  mounted() {
    this.$store.commit("SET_DRAWER", false);
  },
};
</script>

<style lang="sass" scoped>
.container
  height: 100%
.layout
  height: 100%
</style>